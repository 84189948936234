import React, { useMemo } from "react"

import Layout from "../../components/Layout"
import TitleBlock from "../../components/TitleBlock"
import ClientsBlock from "../../components/ClientsBlock"
import ContactForm from "../../components/ContactForm"
import ContactInfo from "../../components/ContactInfo"
import GoogleMaps from "../../components/GoogleMaps"
import OffsetAnchor from "../../components/OffsetAnchor"
import HorLine from "../../components/HorLine"

import "../../styles/global.scss"
import stylesAboutUs from "./aboutUs.module.scss"

import AboutHero from "../../assets/images/pages/aboutUs/aboutHero.svg"
import { remark } from "remark"
import remarkHtml from "remark-html"
import { graphql, useStaticQuery } from "gatsby"
import createHtml from "../../helpers/createHtml"
import findLangIndex from "../../helpers/findLangIndex";
import useGetBrowserLanguage from "../../hooks/useGetBrowserLanguage"

const getTextFromTags = (str, tag) => {
  let result
  if (tag === "h2") {
    result = str.match(/<h2>(.*?)<\/h2>/g)?.map(val => {
      return val.replace(/<\/?h1>/g, "")
    })
  }
  return result?.[0]
}

const removeTitle = (str, tag) => {
  let result
  if (tag === "h2") {
    result = str.match(/<h2>(.*?)<\/h2>/g)?.map(val => {
      return str.replace(val, "")
    })
  }
  return result?.[0]
}

const BoardItem = ({ image, name, email, phone, children }) => (
  <div className={stylesAboutUs.boardBlock__content__item}>
    <div className={stylesAboutUs.boardBlock__content__item__image}>
      <img src={image} alt={name} />
    </div>
    <div
      className={stylesAboutUs.boardBlock__content__item__name}
      dangerouslySetInnerHTML={createHtml(name)}
    />
    <div className={stylesAboutUs.boardBlock__content__item__text}>
      {children}
    </div>
  </div>
)

const renderBoardItems = item => {
  return (
    <BoardItem image={item?.image} name={item?.name}>
      <div dangerouslySetInnerHTML={createHtml(item?.text)} />
    </BoardItem>
  )
}

const AboutUs = () => {
  const language = useGetBrowserLanguage()

  const { allMarkdownRemark } = useStaticQuery(graphql`
    query AboutUsPageData {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(about-us)/" } }
      ) {
        edges {
          node {
            fileAbsolutePath
            frontmatter {
              mini_title
              title
              title_description
              board_title
              board_people {
                image
                name
              }
              board_paragraphs
            }
          }
        }
      }
    }
  `)

  const remarkObj = useMemo(() => remark().use(remarkHtml), [])

  const pageData = useMemo(() => {
    const langIdxEn = findLangIndex(allMarkdownRemark?.edges, "about-us/about-us.md", "en");

    return {
      en: {
        miniTitle: allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.mini_title,
        title: allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.title,
        titleDescription: remarkObj
          .processSync(
            allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.title_description
          )
          .toString(),
        boardTitle: allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.board_title,
        boardDescription: remarkObj
          .processSync(
            allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.board_paragraphs
          )
          .toString(),
        boardPeople:
          allMarkdownRemark?.edges?.[langIdxEn]?.node?.frontmatter?.board_people?.map(
            it => {
              return {
                image: it?.image,
                name: getTextFromTags(
                  remarkObj.processSync(it?.name).toString(),
                  "h2"
                ),
                text: removeTitle(
                  remarkObj.processSync(it?.name).toString(),
                  "h2"
                ),
              }
            }
          ),
      },
    }
  }, [remarkObj, allMarkdownRemark])

  return (
    <Layout>
      <OffsetAnchor anchorId="ourStory" />
      <TitleBlock
        className={stylesAboutUs.titleBlock}
        miniTitle={pageData?.en?.miniTitle}
        title={pageData?.en?.title}
        image={<AboutHero className={stylesAboutUs.titleBlock__mainImg} />}
      >
        <div dangerouslySetInnerHTML={createHtml(pageData?.en?.titleDescription)}/>
      </TitleBlock>
      
      <div className={stylesAboutUs.grayBlock}>
        <div
          className={stylesAboutUs.aboutUsBlock}
          dangerouslySetInnerHTML={createHtml(pageData?.en?.boardDescription)}
        />
      </div>

      <div className={stylesAboutUs.grayBlock}>
        <div className={stylesAboutUs.boardBlock}>
          <div className={stylesAboutUs.boardBlock__title}>
            {pageData?.en?.boardTitle}
          </div>
          <div className={stylesAboutUs.boardBlock__content}>
            {pageData?.en?.boardPeople?.map(item => renderBoardItems(item))}
          </div>
        </div>
      </div>

      <div className={stylesAboutUs.grayBlock}>
        <div className={stylesAboutUs.ourClientsBlock}>
          <OffsetAnchor anchorId="ourClients" />
          <ClientsBlock darkMode />
        </div>
        <div className={stylesAboutUs.contactBlock}>
          <OffsetAnchor anchorId="contactUs" />
          <div className={stylesAboutUs.contactBlock__title}>Contact us</div>
          <div className={stylesAboutUs.contactBlock__content}>
            <ContactForm
              className={stylesAboutUs.contactBlock__content__form}
              darkMode
            />
            <ContactInfo
              className={stylesAboutUs.contactBlock__content__info}
              darkMode
            />
          </div>
        </div>
      </div>
      <GoogleMaps />
    </Layout>
  )
}

export default AboutUs
